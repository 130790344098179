import React, { useState, useEffect } from "react";
import { useAlert } from "react-alert";
import useAuthorization from "../../talons/useAuthorization";
import "./login.css";
import "../index.css";

const Login = (props) => {
    if (localStorage.getItem("userHasAuthenticated")) {
        props.history.push("/pos");
    }

    const alert = useAlert();
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [logIn, setLogIn] = useState(false);
    const { agentLogin, loginData: { loading, data } } = useAuthorization();

    const handleSubmit = () => {
        setLogIn(true);
        agentLogin({ username: email, password: password });
    }

    const handleSubmitByEnter = (e) => {
        if (e.key === 'Enter') {
            setLogIn(true);
            agentLogin({ username: email, password: password });
        }
    }

    useEffect(() => {
        if (typeof data != "undefined") {
            if (data.agentlogin && data.agentlogin.success && logIn) {
                props.userHasAuthenticated(true);
                localStorage.setItem("userHasAuthenticated", true);
                localStorage.setItem("agentName", data.agentlogin.name);
                localStorage.setItem("agentId", data.agentlogin.id);
                localStorage.setItem("currency_code", data.agentlogin.currency_code);
                localStorage.setItem("currency_code", data.agentlogin.currency_symbol);
                localStorage.setItem("store_id", data.agentlogin.store_id);
                localStorage.setItem("store_category_id", data.agentlogin.store_category_id);
                localStorage.setItem("warehouseId", data.agentlogin.warehouse_id);
                localStorage.setItem("source_code", data.agentlogin.source_code);
                localStorage.setItem("login_token", data.agentlogin.login_token);
                localStorage.setItem("store_code", data.agentlogin.store_code);
                localStorage.setItem("store_name", data.agentlogin.store_name);
                localStorage.setItem('storage_modes', JSON.stringify(data.agentlogin.storage_modes));
                localStorage.setItem('storage_mode', data.agentlogin.storage_modes[0].mode);
                localStorage.setItem('countries', data.agentlogin.countries);
                localStorage.setItem("agentDetails", JSON.stringify(data.agentlogin));
                props.history.push('/pos');
            }
            if (data.agentlogin && !data.agentlogin.success && logIn) {
                setLogIn(false);
                alert.error("Username or password is wrong.");
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data, logIn, props]);

    function validateForm() {
        return email.length > 0 && password.length > 0;
    }

    return (
        <div className="login-form-wrapper">
            <div className="login-form">
                <form className="ng-pristine ng-invalid ng-touched" noValidate="">
                    <h1 className="title login-title">login to continue!</h1>
                    <p className="sub-title">Please enter your Email/Contact Number and Password to login</p>
                    <div className="input-field email-field">
                        <input aria-label="email" autoFocus="" value={email} onChange={e => setEmail(e.target.value)} placeholder="E-mail" onKeyDown={handleSubmitByEnter} type="email" className="ng-pristine ng-invalid error ng-touched" />
                    </div>
                    <div className="input-field password-field">
                        <input aria-label="password" placeholder="Password" value={password} onChange={e => setPassword(e.target.value)} onKeyDown={handleSubmitByEnter} type="password" className="ng-untouched ng-pristine ng-invalid" />
                    </div>

                    <div className="action">

                        <button className="submit-btn form-btn position-relative" type="button" onClick={handleSubmit} onKeyDown={handleSubmitByEnter} disabled={!validateForm()}>
                            <span className="login-text">Log in</span>
                            {loading && <div className="ripple"></div>}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default Login;
