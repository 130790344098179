import React, { useState, useContext } from "react";
import PropTypes from 'prop-types';
import Navigation from "../Navigation/Navigation";
import Cart from "../Cart/Cart";
import Products from "../Products/Products";
import { useCustomer } from '../../talons/useCustomer';
import GlobalMessage from '../GlobalMessage/GlobalMessage';
import { Store } from '../../Store';
import "./pos.css";

const Pos = (props) => {
	const { history } = props;
	if(!localStorage.getItem("userHasAuthenticated")){
		history.push("/");
	}

	const { state } = useContext(Store);
    const [showLoader, setShowLoader] = useState(false);
    const [customer, setCustomer] = useState("Set Customer");
    const { customerData, getCustomerToken } = useCustomer();

    const setCustomerData = async (customer) => {
		setShowLoader(true);
		const customerDetails = {
			"id" : customer.id,
			"name" : customer.name,
			"email" : customer.email,
			"contact_number" : customer.contact_number,
		};
		let visualCustomerName = customer.name;
		if (customer.email) {
			visualCustomerName = visualCustomerName + " - " + customer.email;
		}
		setCustomer(visualCustomerName);

        const warehouseId = localStorage.getItem('warehouseId');
		const fromQuoteId = localStorage.getItem('quote_id');

		localStorage.removeItem('customerToken');
		localStorage.removeItem('quote_id');
		localStorage.removeItem('cartDetails');
		localStorage.removeItem('cart_hash');
		localStorage.setItem('customerId', customer.id);
		localStorage.setItem('customerName', visualCustomerName);
		localStorage.setItem('customerDetail', JSON.stringify(customerDetails));
		await getCustomerToken({ customerId: customer.id, warehouseId, fromQuoteId });
		setShowLoader(false);
	};

	const setDefaultCustomer = () => {
		setCustomerData(state.customerList[0]);
	};

	return (
		<div className="dashboard-design-page wrapper">
			<GlobalMessage />
			<Navigation />
			<div className="container-fluid">
				<div className="row">
					<div className="col-lg-8 col-md-7 col-sm-12 p-0">
						<Products
							history={history}
							setCustomerData={setDefaultCustomer}
						/>
					</div>
					<div className="col-lg-4 col-md-5 col-sm-12 p-0 d-lg-block position-relative">
						<Cart
							history={history}
							setCustomerData={setCustomerData}
							showLoader={showLoader}
							customer={customer}
							setCustomer={setCustomer}
							customerData={customerData}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

Pos.propTypes = {
	history: PropTypes.shape({}),
};

export default Pos;
