import { useState } from 'react';
import { useApolloClient } from '@apollo/react-hooks';
import { useTranslations } from '../Components/Translations';
import { usePosApolloApi } from '../posApolloAPI';
import { AUTHORIZATION_LOGOUT, AGENTLOGIN_MUTATION } from '../queries/authorizationSchemas';

const useAuthorization = () => {
    const { fetchPosMutation } = usePosApolloApi();
    const client = useApolloClient();
    const { getTranslations } = useTranslations();
    const [loginData, setLoginData] = useState({
        loading: false,
        data: {}
    });

    const handleLogOut = (history) => {
        fetchPosMutation({ mutation: AUTHORIZATION_LOGOUT });
        localStorage.clear();
        history.push('/');
    };

    const agentLogin = (variables) => {
        setLoginData({ ...loginData, loading: true })

        return client.mutate({
            mutation: AGENTLOGIN_MUTATION,
            variables,
        }).then((response) => {
                setLoginData({ data: response.data, loading: false });
                
                const { agentlogin } = response.data;
                
                getTranslations(
                    { store_id: agentlogin.store_id },
                    {
                        'Content-Type': 'application/json',
                        'Seller': agentlogin.login_token,
                        'Storage': agentlogin.store_name,
                        'Storage-Mode': agentlogin.storage_modes[0].mode,
                        'Authorization': 'Bearer ' + agentlogin.login_token,
                        'Store': agentlogin.store_code,
                        'Content-Currency': agentlogin.currency_code,
                    }
                );
        }).catch((error) => {
            setLoginData({ loading: false, data: {} });
        });
    };

    return {
        handleLogOut,
        agentLogin,
        loginData,
    };
};

export default useAuthorization;